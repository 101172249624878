.viewport-size-widget {
  position: fixed;
  padding: 5px 10px;
  margin: 10px;
  font-size: 0.9rem;
  background: #dddddd;
  opacity: 0.5;
  transition: all 0.3s ease-out;
  cursor: none;
  z-index: 99999999;
  color: #555555;
}

.viewport-size-widget.top-left {
  top: 0;
  left: 0;
}

.viewport-size-widget.top-right {
  top: 0;
  right: 0;
}

.viewport-size-widget.bottom-left {
  bottom: 0;
  left: 0;
}

.viewport-size-widget.bottom-right {
  bottom: 0;
  right: 0;
}

.viewport-size-widget:hover {
  opacity: 1;
  font-size: 1rem;
}